var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"dataTable"}),_c('v-card',{staticClass:"rounded-xl pt-2 pb-4",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('completedOrders.searchLabel'),"single-line":"","clearable":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.states,"label":_vm.$t('completedOrders.status'),"clearable":""},model:{value:(_vm.state),callback:function ($$v) {_vm.state=$$v},expression:"state"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-select',{attrs:{"items":_vm.methods,"label":_vm.$t('completedOrders.paymentMethodLabel'),"clearable":""},model:{value:(_vm.paymentMethod),callback:function ($$v) {_vm.paymentMethod=$$v},expression:"paymentMethod"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('completedOrders.dateFromLabel'),"persistent-hint":"","readonly":"","clearable":""},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateFrom),callback:function ($$v) {_vm.menuDateFrom=$$v},expression:"menuDateFrom"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuDateFrom = false}},model:{value:(_vm.dateFrom),callback:function ($$v) {_vm.dateFrom=$$v},expression:"dateFrom"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$t('completedOrders.dateToLabel'),"persistent-hint":"","clearable":"","readonly":""},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuDateTo),callback:function ($$v) {_vm.menuDateTo=$$v},expression:"menuDateTo"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menuDateTo = false}},model:{value:(_vm.dateTo),callback:function ($$v) {_vm.dateTo=$$v},expression:"dateTo"}})],1)],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.orders,"items-per-page":_vm.itemsPerPage,"loading":_vm.loading,"page":_vm.page,"server-items-length":_vm.totalItems,"hide-default-footer":"","multi-sort":false,"must-sort":true},on:{"update:page":function($event){return _vm.$vuetify.goTo(_vm.$refs.dataTable)},"update:sort-by":_vm.sortBy,"update:sort-desc":_vm.sortDesc,"click:row":_vm.details,"pagination":_vm.pagination},scopedSlots:_vm._u([{key:"item.ordinalNumber",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_vm._v(" "+_vm._s(index+1)+". ")]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateFormat")(item.created,'YYYY-MM-DD'))+" ")]}},{key:"item.state",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.state))]),(item.stateChangeDate)?_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm._f("dateFormat")(item.stateChangeDate,'YYYY-MM-DD')))]):_vm._e()]}},{key:"item.lastPickUpCodeSentDates",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm._f("dateFormat")(item.lastPickUpCodeSentDate,"DD.MM.YYYY HH:mm")))]),(item.lastPickUpCodeSentDate)?_c('div',{staticClass:"text-caption"},[_c('v-chip',{staticClass:"px-2",attrs:{"x-small":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$t('cashierOrder.sent'))+" "+_vm._s(_vm.howManyTimes(item.sentPickUpCodeCount))+" ")])],1):_vm._e()]}},{key:"item.totalChargeAmount",fn:function(ref){
var item = ref.item;
return [(item.totalChargeAmount)?_c('div',[_vm._v(_vm._s(item.totalChargeAmount.toFixed(2))+" "+_vm._s(_vm.$t('cashierOrder.currency')))]):_vm._e()]}},(_vm.pageCount > 1)?{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"mt-4",attrs:{"length":_vm.pageCount,"total-visible":7},on:{"input":_vm.handlePageChange},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}:null],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }